<template>
  <teleport to="body">
    <miscellaneous-information-modal
      v-if="!miscIsLoading"
      :animateButton="miscellaneousInformationAnimateButton"
      :getMiscData="getMiscData"
      :class="{ hidden: showMiscellaneousInformation }"
      @submit-modal="submitMiscInformation"
      @close-modal="toggleMiscellaneousModal"
    ></miscellaneous-information-modal>
    <registrations-modal
      v-if="!registrationsIsLoading"
      :animateButton="registrationsAnimateButton"
      :getRegistrations="getRegistrations"
      :class="{ hidden: showRegistrations }"
      @submit-modal="submitRegistrations"
      @close-modal="toggleRegistrationsModal"
    ></registrations-modal>
    <personal-information-modal
      v-if="!isLoading"
      :animateButton="animateButton"
      :class="{ hidden: hideModal }"
      :profileData="getUser"
      @submit-modal="submitModal"
      @close-modal="toggleModal"
    ></personal-information-modal>
    <education-information-modal
      v-if="!showEducationInformation"
      :userID="getUser.id"
      :getEducationInformation="education"
      :animateButton="educationInformationAnimateButton"
      :class="{ hidden: showEducationInformation }"
      @submit-modal-create="submitEducationCreate"
      @submit-modal="submitEducation"
      @close-modal="toggleEducationInformationModal"
      @delete-item="deleteEducation"
    ></education-information-modal>
    <references-modal
      v-if="!showReferences"
      :userID="getUser.id"
      :getReferencesData="references"
      :animateButton="referencesAnimateButton"
      :class="{ hidden: showReferences }"
      @submit-modal-create="submitReferencesCreate"
      @submit-modal="submitReferences"
      @close-modal="toggleReferencesModal"
      @delete-item="deleteReference"
    ></references-modal>
    <experiences-modal-single
      v-if="!showExperiences"
      :userID="getUser.id"
      :animateButton="experiencesAnimateButton"
      :getExperiencesInformation="experiences"
      :class="{ hidden: showExperiences }"
      @submit-modal-create="submitExperiencesCreate"
      @submit-modal="submitExperiences"
      @close-modal="toggleExperiencesModal"
      @delete-item="deleteExperience"
    ></experiences-modal-single>
  </teleport>
  <profile-header
    v-if="!isLoading"
    :profileData="getUser"
    :hideTabs="true"
    :hideModalButton="true"
    @personalInfoModal="toggleModal"
  />
  <div class="flex w-full gap-x-2 px-3">
    <div class="w-1/2">
      <miscellaneous-information
        :getMiscData="getMiscData"
        :hideEditButton="true"
        @modalTrigger="toggleMiscellaneousModal"
      />
      <registrations
        v-if="!registrationsIsLoading"
        :getRegistrations="getRegistrations"
        :hideEditButton="true"
        @modalTrigger="toggleRegistrationsModal"
      />
    </div>
    <div class="w-1/2 flex flex-col">
      <div>
        <education-information
          :getEducationInformation="getEducationInformation"
          :hideEditButton="true"
          @modalTrigger="toggleEducationInformationModal"
        />
      </div>
      <div>
        <experience-information
          :getExperiencesInformation="getExperiencesInformation"
          :hideEditButton="true"
          @modalTrigger="toggleExperiencesModal"
        />
      </div>
      <reference
        :getReferencesData="getReferencesData"
        :hideEditButton="true"
        @modalTrigger="toggleReferencesModal"
      />
    </div>
  </div>
</template>
  
  <script>
import personalInformationModal from "@/components/modal/profile/MA/personalInformationModal.vue";
import profileHeader from "@/components/layout/profile/profileHeader.vue";
import miscellaneousInformation from "@/components/layout/profile/tabs/employmentHR/miscellaneousInformation.vue";
import registrations from "@/components/layout/profile/tabs/employmentHR/registrations.vue";
import educationInformation from "@/components/layout/profile/tabs/employmentHR/educationInformation.vue";
import experienceInformation from "@/components/layout/profile/tabs/employmentHR/experienceInformation.vue";
import reference from "@/components/layout/profile/tabs/employmentHR/reference.vue";
import educationInformationModal from "@/components/modal/profile/MA/employmentHr/educationInformationModal.vue";
import experiencesModalSingle from "@/components/modal/profile/MA/employmentHr/experiencesModalSingle.vue";
import referencesModal from "@/components/modal/profile/MA/employmentHr/referencesModal.vue";
import miscellaneousInformationModal from "@/components/modal/profile/MA/employmentHr/miscellaneousInformationModal.vue";
import registrationsModal from "@/components/modal/profile/MA/employmentHr/registrationsModal.vue";
export default {
  components: {
    personalInformationModal,
    profileHeader,
    miscellaneousInformation,
    miscellaneousInformationModal,
    registrations,
    educationInformation,
    experienceInformation,
    reference,
    educationInformationModal,
    experiencesModalSingle,
    referencesModal,
    registrationsModal,
  },
  created() {
    this.fetchMiscInfo();
    this.fetchUserData();
    this.fetchEducationInformation();
    this.fetchExperiencesInformation();
    this.fetchReferencesInformation();
  },
  data() {
    return {
      userId: localStorage.getItem("user_id"),
      hideModal: true,
      isLoading: false,
      miscIsLoading: false,
      registrationsIsLoading: false,
      animateButton: false,

      experiencesInformationIsLoading: false,
      // Education Information
      showEducationInformation: true,
      educationInformationAnimateButton: false,
      // References
      showReferences: true,
      referencesAnimateButton: false,
      // References
      showExperiences: true,
      experiencesAnimateButton: false,
      // Miscellaneous Information
      showMiscellaneousInformation: true,
      miscellaneousInformationAnimateButton: false,
      // Registrations
      showRegistrations: true,
      registrationsAnimateButton: false,
    };
  },
  methods: {
    async fetchUserData() {
      try {
        this.isLoading = true;
        await this.$store.dispatch("REnewHire/fetchUserData", {
          user: this.userId,
        });
        this.isLoading = false;
      } catch (error) {
        this.error = error.message || "Something went wrong!";
      }
    },
    async fetchMiscInfo() {
      try {
        this.miscIsLoading = true;
        await this.$store.dispatch("profile/fetchMiscInfo", {
          user: this.userId,
        });
        this.miscIsLoading = false;
      } catch (error) {
        this.error = error.message || "Something went wrong!";
      }
    },
    async fetchEducationInformation() {
      try {
        this.educationInformationIsLoading = true;
        await this.$store.dispatch("profile/fetchEducationInformation", {
          user: this.userId,
        });
        this.educationInformationIsLoading = false;
      } catch (error) {
        this.error = error.message || "Something went wrong!";
      }
    },
    async fetchExperiencesInformation() {
      try {
        this.experiencesInformationIsLoading = true;
        await this.$store.dispatch("profile/fetchExperiencesInformation", {
          user: this.userId,
        });
        this.experiencesInformationIsLoading = false;
      } catch (error) {
        this.error = error.message || "Something went wrong!";
      }
    },
    async fetchReferencesInformation() {
      try {
        this.referencesIsLoading = true;
        await this.$store.dispatch("profile/fetchReferences", {
          user: this.userId,
        });
        this.referencesIsLoading = false;
      } catch (error) {
        this.error = error.message || "Something went wrong!";
      }
    },
    toggleModal() {
      this.hideModal = !this.hideModal;
    },
    toggleRegistrationsModal() {
      this.showRegistrations = !this.showRegistrations;
    },
    toggleEducationInformationModal(data) {
      this.showEducationInformation = !this.showEducationInformation;
      this.education = data;
    },
    toggleReferencesModal(data) {
      this.showReferences = !this.showReferences;
      this.references = data;
    },
    toggleExperiencesModal(data) {
      this.showExperiences = !this.showExperiences;
      this.experiences = data;
    },
    toggleMiscellaneousModal() {
      this.showMiscellaneousInformation = !this.showMiscellaneousInformation;
    },
    async submitModal(actionName, actionPayload) {
      this.animateButton = true;
      try {
        await this.submitAction(actionName, actionPayload);
        this.hideModal = !this.hideModal;
        this.animateButton = false;
      } catch (error) {
        this.animateButton = false;
      }
    },
    // Misc Information Update
    async submitMiscInformation(actionName, actionPayload) {
      this.miscellaneousInformationAnimateButton = true;
      try {
        await this.submitAction(actionName, actionPayload);
        this.showMiscellaneousInformation = !this.showMiscellaneousInformation;
        this.miscellaneousInformationAnimateButton = false;
        this.fetchMiscInfo();
      } catch (error) {
        this.miscellaneousInformationAnimateButton = false;
      }
    },
    // Registrations Update
    async submitRegistrations(actionName, actionPayload) {
      for (var pair of actionPayload.entries()) {
        console.log(pair[0] + ", " + pair[1]);
      }
      this.registrationsAnimateButton = true;
      try {
        await this.submitAction(actionName, actionPayload);
        this.showRegistrations = !this.showRegistrations;
        this.registrationsAnimateButton = false;
      } catch (error) {
        this.registrationsAnimateButton = false;
      }
    },
    // Trigger Action
    async submitAction(actionName, actionPayload) {
      try {
        await this.$store.dispatch(actionName, actionPayload);
        this.fetchUserData();
        this.$toast.success(`Updated Successful`);
      } catch (error) {
        this.$toast.error("Oops something went wrong");
      }
    },

    // Education Create
    async submitEducationCreate(actionName, actionPayload) {
      this.educationInformationAnimateButton = true;
      try {
        await this.submitAction(actionName, actionPayload);
        this.showEducationInformation = !this.showEducationInformation;
        this.educationInformationAnimateButton = false;
        this.fetchEducationInformation();
      } catch (error) {
        this.educationInformationAnimateButton = false;
      }
    },
    // Education Update
    async submitEducation(actionName, actionPayload) {
      this.educationInformationAnimateButton = true;
      try {
        await this.submitAction(actionName, actionPayload);
        this.showEducationInformation = !this.showEducationInformation;
        this.educationInformationAnimateButton = false;
        this.fetchEducationInformation();
      } catch (error) {
        this.educationInformationAnimateButton = false;
      }
    },
    // Experiences Create
    async submitExperiencesCreate(actionName, actionPayload) {
      this.experiencesAnimateButton = true;
      try {
        await this.submitAction(actionName, actionPayload);
        this.showExperiences = !this.showExperiences;
        this.experiencesAnimateButton = false;
        this.fetchExperiencesInformation();
      } catch (error) {
        this.experiencesAnimateButton = false;
      }
    },
    // Experiences Update
    async submitExperiences(actionName, actionPayload) {
      this.experiencesAnimateButton = true;
      try {
        await this.submitAction(actionName, actionPayload);
        this.showExperiences = !this.showExperiences;
        this.experiencesAnimateButton = false;
        this.fetchExperiencesInformation();
      } catch (error) {
        this.experiencesAnimateButton = false;
      }
    },
    // References Create
    async submitReferencesCreate(actionName, actionPayload) {
      this.referencesAnimateButton = true;
      try {
        await this.submitAction(actionName, actionPayload);
        this.showReferences = !this.showReferences;
        this.referencesAnimateButton = false;
        this.fetchReferencesInformation();
      } catch (error) {
        this.referencesAnimateButton = false;
      }
    },
    // References Update
    async submitReferences(actionName, actionPayload) {
      this.referencesAnimateButton = true;
      try {
        await this.submitAction(actionName, actionPayload);
        this.showReferences = !this.showReferences;
        this.referencesAnimateButton = false;
        this.fetchReferencesInformation();
      } catch (error) {
        this.referencesAnimateButton = false;
      }
    },
    async deleteEducation(data) {
      try {
        await this.$store.dispatch("profile/deleteEducation", data);
        this.$toast.success(`Delete Successful`);
        this.showEducationInformation = false;
        this.fetchEducationInformation();
      } catch (error) {
        this.$toast.error("Oops something went wrong");
      }
    },
    async deleteExperience(data) {
      try {
        await this.$store.dispatch("profile/deleteExperience", data);
        this.$toast.success(`Delete Successful`);
        this.showExperiences = true;
        this.fetchExperiencesInformation();
      } catch (error) {
        this.$toast.error("Oops something went wrong");
      }
    },
    async deleteReference(data) {
      try {
        await this.$store.dispatch("profile/deleteReference", data);
        this.$toast.success(`Delete Successful`);
        this.showReferences = true;
        this.fetchReferencesInformation();
      } catch (error) {
        this.$toast.error("Oops something went wrong");
      }
    },
  },
  computed: {
    getMiscData() {
      return this.$store.getters["profile/getMiscData"];
    },
    getRegistrations() {
      return this.$store.getters["profile/getRegistrations"];
    },
    getUser() {
      return this.$store.getters["REnewHire/getUser"];
    },
    getEducationInformation() {
      return this.$store.getters["profile/getEducationInformation"];
    },
    getExperiencesInformation() {
      return this.$store.getters["profile/getExperiencesInformation"];
    },
    getReferencesData() {
      return this.$store.getters["profile/getReferencesData"];
    },
  },
};
</script>
  