<template>
  <form @submit.prevent="submitModal">
    <div
      class="
        flex
        justify-center
        h-screen
        w-screen
        items-center
        z-20
        absolute
        top-0
        left-0
        -translate-x-1/2
      "
    >
      <div
        class="h-screen w-screen bg-grey bg-opacity-60 absolute"
        @click="closeModal"
      ></div>
      <div
        class="
          hideScroll
          flex flex-col
          justify-center
          align-middle
          min-w-lg
          max-w-6xl
          mx-auto
          rounded-lg
          z-20
          overflow-scroll
          h-5/6
          overflow-x-hidden
        "
      >
        <!-- Modal Body -->
        <div class="flex flex-col p-4 bg-white">
          <!-- Title -->
          <h2 class="font-semibold text-teal text-2xl self-center pb-2">
            Registrations
          </h2>
          <div v-if="getRegistrations && getRegistrations.is_nurse">
            <!-- NMC Divider -->
            <div>
              <h3
                class="
                  w-4/5
                  mt-3
                  mx-auto
                  overflow-hidden
                  text-center text-orange
                "
              >
                NMC
              </h3>
            </div>
            <!-- Name / Geographical Location -->
            <div
              class="
                flex flex-col
                items-center
                sm:flex-row
                my-1
                sm:ml-2
                md:ml-10
              "
            >
              <!-- Name -->
              <div class="w-full sm:w-1/2">
                <label
                  class="font-semibold text-grey flex pb-1"
                  for="employeeName"
                  >Name</label
                >
                <input
                  placeholder=""
                  type="text"
                  name="employeeName"
                  v-model="employeeName"
                  class="
                    rounded-lg
                    border border-darkGrey
                    bg-lightGrey
                    w-full
                    sm:w-11/12
                    h-10
                    pl-2
                  "
                />
              </div>
              <!-- Geographical Location -->
              <div class="w-full sm:w-1/2">
                <label
                  class="font-semibold text-grey flex pb-1"
                  for="geographicalLocation"
                  >Geographical Location</label
                >
                <input
                  placeholder=""
                  type="text"
                  name="geographicalLocation"
                  v-model="geographicalLocation"
                  class="
                    rounded-lg
                    border border-darkGrey
                    bg-lightGrey
                    w-full
                    sm:w-11/12
                    h-10
                    pl-2
                  "
                />
              </div>
            </div>
            <!-- Expiry Date / Registration Status -->
            <div
              class="
                flex flex-col
                items-center
                sm:flex-row
                my-1
                sm:ml-2
                md:ml-10
              "
            >
              <!-- Expiry Date -->
              <div class="w-full sm:w-1/2">
                <label
                  class="font-semibold text-grey flex pb-1"
                  for="nmcExpiryDate"
                  >Expiry Date</label
                >
                <input
                  placeholder="MM/DD/YYYY"
                  onfocus="(this.type = 'date')"
                  type="text"
                  name="nmcExpiryDate"
                  v-model="nmcExpiryDate"
                  class="
                    rounded-lg
                    border border-darkGrey
                    bg-lightGrey
                    w-full
                    sm:w-11/12
                    h-10
                    pl-2
                  "
                />
              </div>
              <!-- Registration Status -->
              <div class="w-full sm:w-1/2">
                <label
                  class="font-semibold text-grey flex pb-1"
                  for="nmcRegistrationStatus"
                  >Registration Status</label
                >
                <select
                  name="nmcRegistrationStatus"
                  class="
                    rounded-lg
                    border border-darkGrey
                    bg-lightGrey
                    w-full
                    sm:w-11/12
                    h-10
                    pl-2
                  "
                  v-model="nmcRegistrationStatus"
                >
                  <option value="">Choose an option</option>
                  <option value="Registered">Registered</option>
                  <option value="Not Currently Practising">
                    Not currently practising
                  </option>
                  <option value="Removed By Fitness To Practice (FtP) Panel">
                    Removed by a fitness to practise (FtP) panel
                  </option>
                  <option value="Suspended By FtP">
                    Suspended by an FtP panel
                  </option>
                  <option value="Voluntarily Removed">
                    Voluntarily removed
                  </option>
                  <option value="Interim Suspension Order">
                    Interim suspension order
                  </option>
                  <option value="Conditions Of Practice Order">
                    Conditions of practice order
                  </option>
                  <option value="Interim Conditions">Interim conditions</option>
                  <option value="Undertakings">Undertakings</option>
                  <option value="Sanction Pending">Sanction pending</option>
                  <option value="Caution Order">Sanction pending</option>
                  <option value="Warnings">Warnings</option>
                  <option value="No Recorded Qualifications Found">
                    No Recorded Qualifications found
                  </option>
                </select>
              </div>
            </div>
            <!-- Register Entry / Register Entry Date-->
            <div
              class="
                flex flex-col
                items-center
                sm:flex-row
                my-1
                sm:ml-2
                md:ml-10
              "
            >
              <!-- Register Entry -->
              <div class="w-full sm:w-1/2">
                <label
                  class="font-semibold text-grey flex pb-1"
                  for="nmcRegisterEntry"
                  >Register Entry</label
                >
                <input
                  placeholder=""
                  type="text"
                  name="nmcRegisterEntry"
                  v-model="nmcRegisterEntry"
                  class="
                    rounded-lg
                    border border-darkGrey
                    bg-lightGrey
                    w-full
                    sm:w-11/12
                    h-10
                    pl-2
                  "
                />
              </div>
              <!-- Register Entry Date-->
              <div class="w-full sm:w-1/2">
                <label
                  class="font-semibold text-grey flex pb-1"
                  for="nmcRegisterEntryDate"
                  >Register Entry Date</label
                >
                <input
                  placeholder="MM/DD/YYYY"
                  onfocus="(this.type = 'date')"
                  type="text"
                  name="nmcRegisterEntryDate"
                  v-model="nmcRegisterEntryDate"
                  class="
                    rounded-lg
                    border border-darkGrey
                    bg-lightGrey
                    w-full
                    sm:w-11/12
                    h-10
                    pl-2
                  "
                />
              </div>
            </div>
            <!-- Recorded Qualifications -->
            <div class="sm:flex my-1 md:ml-10">
              <div class="w-full">
                <label
                  class="font-semibold text-grey flex pb-1"
                  for="recordedQualification"
                  >Recorded Qualification</label
                >
                <!-- Dynamic Recorded Qualification -->
                <div class="my-1">
                  <div
                    class="sm:my-1"
                    v-for="(qualification, index) in recordedNMCQualifications"
                    :key="qualification"
                  >
                    <input
                      type="text"
                      v-model="qualification.name"
                      :name="'equipment' + index"
                      class="
                        rounded-lg
                        border border-darkGrey
                        bg-lightGrey
                        w-full
                        sm:w-11/12
                        h-10
                        pl-2
                      "
                    />
                    <!-- <div class="">
                      <p
                        class="sm:hidden w-20 py-1 mx-1 my-1 md:mr-5 text-center text-white bg-red rounded-full cursor-pointer"
                      >
                        Delete
                      </p>
                    </div> -->
                  </div>
                </div>
              </div>
              <div class="w-full">
                <label
                  class="font-semibold text-grey flex pb-1"
                  for="recordedQualification"
                  >Qualification Date</label
                >
                <!-- Dynamic Qualification Date -->
                <div class="my-1">
                  <div
                    class="sm:flex sm:justify-between sm:my-1"
                    v-for="(qualification, index) in recordedNMCQualifications"
                    :key="qualification"
                  >
                    <input
                      placeholder="MM/DD/YYYY"
                      onfocus="(this.type = 'date')"
                      type="text"
                      :name="'qualification' + index"
                      v-model="qualification.date"
                      class="
                        rounded-lg
                        border border-darkGrey
                        bg-lightGrey
                        w-full
                        sm:w-11/12
                        h-10
                        pl-2
                      "
                    />
                    <!-- <div class="justify-end">
                      <p
                        class="w-20 py-1 mx-1 my-1 md:mr-5 text-center text-white bg-red rounded-full cursor-pointer"
                        @click.prevent="deleteNMCOption(index)"
                      >
                        Delete
                      </p>
                    </div> -->
                  </div>
                  <!-- <div class="flex justify-end">
                    <p
                      class="w-28 mx-1 my-1 md:mr-5 text-center text-white bg-teal rounded-full cursor-pointer"
                      @click.prevent="addNMCOption"
                    >
                      Add
                    </p>
                  </div> -->
                </div>
              </div>
            </div>
            <!-- NCM Document -->
            <div
              class="
                flex flex-col
                items-center
                sm:flex-row
                my-1
                sm:ml-2
                md:ml-10
              "
            >
              <div class="w-full">
                <label
                  class="font-semibold text-grey flex pb-1"
                  for="nmcOfficialDocument"
                  >NCM Official Document</label
                >
                <div class="relative w-full">
                  <input
                    type="file"
                    ref="file"
                    name="nmcOfficialDocument"
                    class="relative text-right opacity-0 z-20 w-11.5/12 h-10"
                    @change="uploadFile"
                  />
                  <div class="absolute top-0 left-0 z-10 w-11.5/12 h-10">
                    <input
                      class="rounded-lg border bg-lightGrey pl-2 w-full h-10"
                      :class="[
                        nmcOfficialDocumentSizeOK
                          ? 'border-red'
                          : 'border-darkGrey',
                      ]"
                      :placeholder="
                        checkFile(
                          nmcOfficialDocument,
                          nmcOfficialDocumentSizeOK
                        )
                      "
                    />
                  </div>
                </div>
                <span v-if="nmcOfficialDocumentSizeOK">
                  File is Too Large. Please select a different file
                </span>
              </div>
            </div>
          </div>
          <div >
            <!-- GMC Divider -->
            <div>
              <h3
                class="
                  w-4/5
                  mt-3
                  mx-auto
                  overflow-hidden
                  text-center text-orange
                "
              >
                GMC
              </h3>
            </div>
            <!-- GMC Reference No. / GP Register Date -->
            <div
              class="
                flex flex-col
                items-center
                sm:flex-row
                my-1
                sm:ml-2
                md:ml-10
              "
            >
              <!-- GMC Reference No. -->
              <div class="w-full sm:w-1/2">
                <label
                  class="font-semibold text-grey flex pb-1"
                  for="gmcReferenceNo"
                  >GMC Reference No.</label
                >
                <input
                  placeholder=""
                  type="text"
                  name="gmcReferenceNo"
                  v-model="gmcReferenceNo"
                  class="
                    rounded-lg
                    border border-darkGrey
                    bg-lightGrey
                    w-full
                    sm:w-11/12
                    h-10
                    pl-2
                  "
                />
              </div>
              <!-- GP Register Date -->
              <div class="w-full sm:w-1/2">
                <label
                  class="font-semibold text-grey flex pb-1"
                  for="GPRegisterDate"
                  >GP Register Date</label
                >
                <input
                  placeholder="MM/DD/YYYY"
                  onfocus="(this.type = 'date')"
                  type="text"
                  name="GPRegisterDate"
                  v-model="GPRegisterDate"
                  class="
                    rounded-lg
                    border border-darkGrey
                    bg-lightGrey
                    w-full
                    sm:w-11/12
                    h-10
                    pl-2
                  "
                />
              </div>
            </div>
            <!-- Provisional Register / Full Registration Date -->
            <div
              class="
                flex flex-col
                items-center
                sm:flex-row
                my-1
                sm:ml-2
                md:ml-10
              "
            >
              <!-- Provisional Registration Date -->
              <div class="w-full sm:w-1/2">
                <label
                  class="font-semibold text-grey flex pb-1"
                  for="provisionalRegistrationDate"
                  >Provisional Registration Date</label
                >
                <input
                  placeholder="MM/DD/YYYY"
                  onfocus="(this.type = 'date')"
                  type="text"
                  name="provisionalRegistrationDate"
                  v-model="provisionalRegistrationDate"
                  class="
                    rounded-lg
                    border border-darkGrey
                    bg-lightGrey
                    w-full
                    sm:w-11/12
                    h-10
                    pl-2
                  "
                />
              </div>
              <!-- Full Registration Date -->
              <div class="w-full sm:w-1/2">
                <label
                  class="font-semibold text-grey flex pb-1"
                  for="fullRegistrationDate"
                  >Full Registration Date</label
                >
                <input
                  placeholder="MM/DD/YYYY"
                  onfocus="(this.type = 'date')"
                  type="text"
                  name="fullRegistrationDate"
                  v-model="fullRegistrationDate"
                  class="
                    rounded-lg
                    border border-darkGrey
                    bg-lightGrey
                    w-full
                    sm:w-11/12
                    h-10
                    pl-2
                  "
                />
              </div>
            </div>
            <!-- Specialised Register -->
            <div class="sm:flex my-1 md:ml-10">
              <div class="w-full">
                <label
                  class="font-semibold text-grey flex pb-1"
                  for="recordedQualification"
                  >Recorded Qualification</label
                >
                <!-- Dynamic Recorded Qualification -->
                <div class="my-1">
                  <div
                    class="sm:my-1"
                    v-for="(qualification, index) in specialistRegister"
                    :key="qualification"
                  >
                    <input
                      type="text"
                      v-model="qualification.name"
                      :name="'equipment' + index"
                      class="
                        rounded-lg
                        border border-darkGrey
                        bg-lightGrey
                        w-full
                        sm:w-11/12
                        h-10
                        pl-2
                      "
                    />
                    <!-- <div class="">
                      <p
                        class="sm:hidden w-20 py-1 mx-1 my-1 md:mr-5 text-center text-white bg-red rounded-full cursor-pointer"
                      >
                        Delete
                      </p>
                    </div> -->
                  </div>
                </div>
              </div>
              <div class="w-full">
                <label
                  class="font-semibold text-grey flex pb-1"
                  for="recordedQualification"
                  >Qualification Date</label
                >
                <!-- Dynamic Qualification Date -->
                <div class="my-1">
                  <div
                    class="sm:flex sm:justify-between sm:my-1"
                    v-for="(qualification, index) in specialistRegister"
                    :key="qualification"
                  >
                    <input
                      placeholder="MM/DD/YYYY"
                      onfocus="(this.type = 'date')"
                      type="text"
                      :name="'qualification' + index"
                      v-model="qualification.date"
                      class="
                        rounded-lg
                        border border-darkGrey
                        bg-lightGrey
                        w-full
                        sm:w-11/12
                        h-10
                        pl-2
                      "
                    />
                    <!-- <div class="justify-end">
                      <p
                        class="w-20 py-1 mx-1 my-1 md:mr-5 text-center text-white bg-red rounded-full cursor-pointer"
                        @click.prevent="deleteGMCOption(index)"
                      >
                        Delete
                      </p>
                    </div> -->
                  </div>
                  <!-- <div class="flex justify-end">
                    <p
                      class="w-28 mx-1 my-1 md:mr-5 text-center text-white bg-teal rounded-full cursor-pointer"
                      @click.prevent="addGMCOption"
                    >
                      Add
                    </p>
                  </div> -->
                </div>
              </div>
            </div>
          </div>
        </div>
        <div
          class="
            flex flex-row
            items-center
            justify-end
            p-5
            bg-white
            border-t border-lightGrey
            rounded-bl-lg rounded-br-lg
          "
        >
          <p
            class="
              px-11
              py-2
              text-white
              font-semibold
              bg-orange
              rounded-full
              cursor-pointer
            "
            @click="closeModal"
          >
            Cancel
          </p>
          <base-button :animateButton="animateButton"> Post </base-button>
        </div>
      </div>
    </div>
  </form>
</template>

<script>
export default {
  props: {
    animateButton: {
      type: Boolean,
      required: true,
    },
    getRegistrations: {
      type: Object,
      required: true,
    },
  },
  emits: ["close-modal", "submit-modal"],
  mounted() {
    if (this.getRegistrations) {
      this.legalId = this.getRegistrations.id;
      this.employeeName = this.getRegistrations.name;
      this.geographicalLocation = this.getRegistrations.location;
      this.nmcExpiryDate = this.getRegistrations.expiry_date;
      this.nmcRegistrationStatus = this.getRegistrations.registration_status;
      this.nmcRegisterEntry = this.getRegistrations.register_entry;
      this.nmcRegisterEntryDate = this.getRegistrations.register_entry_date;
      this.nmcOfficialDocument = this.getRegistrations.nmc_document;
      this.recordedNMCQualifications = this.getRegistrations.nmc_qualifications;

      this.gmcReferenceNo = this.getRegistrations.gmc_reference_number;
      this.GPRegisterDate = this.getRegistrations.gp_register_date;
      (this.specialistRegister =
        this.getRegistrations.gmc_specialist_registers),
        (this.provisionalRegistrationDate =
          this.getRegistrations.provisional_registration_date);
      this.fullRegistrationDate = this.getRegistrations.full_registration_date;
    }
  },
  data() {
    return {
      legalId: "",
      employeeName: "",
      geographicalLocation: "",
      nmcExpiryDate: "",
      nmcRegistrationStatus: "",
      nmcRegisterEntry: "",
      nmcRegisterEntryDate: "",
      recordedNMCQualifications: null,
      // [
      //   { id: "13", name: "qual 1", date: "2022-01-11T15:47:52.000000Z" },
      //   { id: "14", name: "qual 2", date: "2022-01-11T15:47:52.000000Z" },
      //   { id: "15", name: "qual 3", date: "2022-01-11T15:47:52.000000Z" },
      // ],
      nmcOfficialDocument: null,
      nmcOfficialDocumentSizeOK: false,
      gmcReferenceNo: "",
      GPRegisterDate: "",
      specialistRegister: [],
      provisionalRegistrationDate: "",
      fullRegistrationDate: "",
    };
  },
  methods: {
    uploadFile() {
      this.nmcOfficialDocument = this.$refs.file.files[0];
      if (this.nmcOfficialDocument.size > 1024 * 1024) {
        this.nmcOfficialDocument = {};
        return (this.nmcOfficialDocumentSizeOK = true);
      } else {
        return (this.nmcOfficialDocumentSizeOK = false);
      }
    },
    checkFile(attachment, sizeCheck) {
      if (typeof attachment === "object" && attachment !== null) {
        if (sizeCheck) {
          return "Click here to attach a file (< 1MB)...";
        } else {
          return attachment.name;
        }
      } else if (attachment !== null) {
        const url = new URL(attachment);
        const newerURL = url.pathname.split("/");
        let lastElement = newerURL[newerURL.length - 1];
        let arr = lastElement.split(".");
        arr.shift();
        lastElement = arr.join(".");
        return lastElement;
      }
      return;
    },
    // addNMCOption() {
    //   this.recordedNMCQualifications.push({
    //     name: "",
    //     date: "",
    //   });
    // },
    // addGMCOption() {
    //   this.specialistRegister.push({
    //     name: "",
    //     date: "",
    //   });
    // },
    // deleteNMCOption(index) {
    //   this.recordedNMCQualifications.splice(index, 1);
    // },
    // deleteGMCOption(index) {
    //   this.specialistRegister.splice(index, 1);
    // },
    closeModal() {
      this.$emit("close-modal");
    },
    submitModal() {
      const formData = new FormData();
      formData.append("legal", this.legalId);
      if (this.getRegistrations.is_nurse) {
        formData.append("name", this.employeeName);
        formData.append("location", this.geographicalLocation);
        formData.append("expiry_date", this.nmcExpiryDate);
        formData.append("registration_status", this.nmcRegistrationStatus);
        formData.append("register_entry", this.nmcRegisterEntry);
        formData.append("register_entry_date", this.nmcRegisterEntryDate);
        if (typeof this.nmcOfficialDocument === "object") {
          formData.append("nmc_document", this.nmcOfficialDocument);
        }
        for (const [index, value] of Object.entries(
          this.recordedNMCQualifications
        )) {
          formData.append(`nmc_qualifications[${index}][id]`, value.id);
          formData.append(`nmc_qualifications[${index}][name]`, value.name);
          formData.append(`nmc_qualifications[${index}][date]`, value.date);
        }
      } else {
        formData.append("gmc_reference_number", this.gmcReferenceNo);
        formData.append("gp_register_date", this.GPRegisterDate);
        formData.append(
          "provisional_registration_date",
          this.provisionalRegistrationDate
        );
        formData.append("full_registration_date", this.fullRegistrationDate);
        for (const specialisation in this.specialistRegister) {
          formData.append(
            `gmc_specialist_registers${specialisation}name`,
            specialisation.name
          );
        }
      }
      this.$emit("submit-modal", "profile/updateRegistration", formData);
    },
  },
};
</script>

<style scoped>
h3:before,
h3:after {
  content: "";
  color: #000;
  display: inline-block;
  width: 50%;
  margin: 0 0.5em 0 -55%;
  vertical-align: middle;
  border-bottom: 1px solid;
}
h3:after {
  margin: 0 -55% 0 0.5em;
}

input[type="radio"] + label span {
  transition: background-color 0.2s, transform 0.2s;
}

input[type="radio"] + label span:hover,
input[type="radio"] + label:hover span {
  transform: scale(1.2);
}

input[type="radio"]:checked + label span {
  background-color: #3490dc;
  box-shadow: 0px 0px 0px 2px white inset;
}

input[type="radio"]:checked + label {
  color: #3490dc;
}
/* Hide scrollbar for Chrome, Safari and Opera */
.hideScroll::-webkit-scrollbar {
  display: none;
}

/* Hide scrollbar for IE, Edge and Firefox */
.hideScroll {
  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none; /* Firefox */
}
</style>
